<template>
  <base-section id="404">
    <base-heading title="404" />

    <base-subheading
      class="primary--text"
      title="Oooops!"
    />

    <base-subheading
      space="8"
      title="Apriori cette page n'existe pas!"
    />

    <!-- <base-img
      :src="require('@/assets/logo-fondIndePresse.jpg')"
      class="mx-auto mb-12"
      contain
      height="350"
      width="250"
    /> -->

    <v-card
      elevation="16"
      class="mx-auto mb-12"

      height="150"
      width="250"
    >
      <base-img
        :src="require('@/assets/logo-fondIndePresse.jpg')"
      />
    </v-card>

    <div class="text-center">
      <base-btn :to="{ name: 'Home' }">
        Revenir à l'accueil
      </base-btn>
    </div>
  </base-section>
</template>

<script>
  export default {
    name: 'FourOhFour',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
